.link--grey,
.link--orange,
.figure__media-overlay-text a,
.link:not(.link--inverted) {
  color: blue !important; }
  .link--grey a:focus,
  .link--grey a:hover, .link--grey:hover, .link--grey:focus,
  .link--orange a:focus,
  .link--orange a:hover,
  .link--orange:hover,
  .link--orange:focus,
  .figure__media-overlay-text a a:focus,
  .figure__media-overlay-text a a:hover,
  .figure__media-overlay-text a:hover,
  .figure__media-overlay-text a:focus,
  .link:not(.link--inverted) a:focus,
  .link:not(.link--inverted) a:hover,
  .link:not(.link--inverted):hover,
  .link:not(.link--inverted):focus {
    color: blue !important; }

.button,
.button--outline:hover,
.button--outline:focus,
.contrast--disable::after,
.file-upload__label,
.select--blue,
.carousel__navigation-item--active,
.icon-button--blue,
.facts,
.faq--blue,
.footer-dark,
.intro-box--blue,
.magazine-menu__list-item,
.navigation-a-z__list-item,
.stage-dark,
.tab-box__tab-list-item,
.teaser-box--blue,
.jobs-teaser-box--blue,
.topics-box--blue,
.body--blue {
  background-color: #484848 !important; }

.button--inverted.button--blue,
.button--outline,
.button--outline.button--inverted.button--blue:hover,
.button--outline.button--inverted.button--blue:focus,
.contrast,
.headline,
.select--selected,
.accordion__title,
.action-list__item--active .action-list__link,
.action-list__link:focus,
.action-list__link:hover,
.breadcrumb--footer .breadcrumb__item,
.font-zoom,
.highlight__text,
.icon-button--shadow,
.icon-button--blue-outline,
.input-with-button__button,
.phone__text,
.table-of-contents__item,
.bill__title--large,
.bill__title--emphasized,
.bill__data,
.bill__highlight,
.bill__footer,
.footer__link,
.footer__nav-title,
.footer__nav-list-sub,
.footer__nav-link-sub,
.gallery__mini-slider-arrow,
.header__toggle,
.header__navigation-link,
.header__search-result,
.intro-box__phone,
.map-tools__result-icon,
.map-tools__overlay-icon,
.sidebar__text,
.topic-box__link-icon,
.u-color-blue,
.teaser-box__icon,
.teaser-box__headline,
.teaser-box:not(.teaser-box--orange):not(.teaser-box--blue),
.jobs-teaser-box__icon,
.jobs-teaser-box__headline,
.jobs-teaser-box:not(.jobs-teaser-box--orange):not(.jobs-teaser-box--blue),
.topics-box__icon {
  color: #484848 !important; }

.button--outline.button--inverted.button--blue:hover,
.button--outline.button--inverted.button--blue:focus {
  color: #fff !important; }

.button--outline,
.file-upload:focus .file-upload__text,
.select:focus,
.select--blue,
.select--blue:hover,
.select--blue:focus,
.icon-button--blue-outline,
.bill__footer,
.contact-box .form,
.form__section {
  border-color: #484848 !important; }

.file-upload__input:hover + .file-upload__label,
.icon-button--blue:hover,
.icon-button--blue:focus,
.icon-button--blue-outline:hover,
.icon-button--blue-outline:focus {
  background-color: #434343; }

.button--action,
.button.button--orange,
.progress-bar__step--complete,
.article-teaser__icon,
.faq--orange,
.gallery__arrow,
.intro-box--orange,
.magazine-menu__list-item:hover,
.magazine-menu__list-item--active,
.newsletter-box,
.teaser-box--orange,
.jobs-teaser-box--orange,
.topics-box--orange,
.body--orange {
  background-color: #9c9c9c !important; }

.button__outline.button--inverted.button--orange:hover,
.button__outline.button--inverted.button--orange:focus,
.label--required::after,
.link--orange,
.link--orange:focus,
.link--orange:hover,
.article-teaser__date,
.header__navigation-link.header__navigation-link--active,
.header__navigation-link:not(.u-fading-out)[aria-expanded='true'],
.header__navigation-link:hover,
.header__navigation-link:not(.u-unvisible-focus):focus,
.header__meta-navigation-link--important,
.sidebar__section--orange .sidebar__text {
  color: #9c9c9c !important; }

.input:focus,
.table__highlight,
.article-teaser__highlight {
  border-color: #9c9c9c; }

.map-tools__result-item:focus .map-tools__result-icon,
.map-tools__result-item:hover .map-tools__result-icon,
.map-tools__result-item--active .map-tools__result-icon {
  color: #9c9c9c; }

.header__navigation > .header__navigation-item > .header__navigation-link.header__navigation-link--active, .header__navigation > .header__navigation-item > .header__navigation-link:not(.u-fading-out)[aria-expanded='true'], .header__navigation > .header__navigation-item > .header__navigation-link:hover, .header__navigation > .header__navigation-item > .header__navigation-link:not(.u-unvisible-focus):focus {
  box-shadow: inset 0 -4px #9c9c9c; }

.gallery__arrow:hover, .gallery__arrow:focus {
  background-color: #8f8f8f; }

.input:focus {
  box-shadow: 0 0 0 1px #9c9c9c; }

.gallery__mini-slider-arrow:hover, .gallery__mini-slider-arrow:focus {
  color: #3b3b3b; }

.stae-hero--bg-light .stage-hero__kicker,
.stae-hero--bg-light .stage-hero__headline,
.stae-hero--bg-light .stage-hero__intro {
  color: #484848 !important; }

.stage-landing__cover::after {
  background-color: rgba(72, 72, 72, 0.3); }

html,
.figure__media-overlay-text,
.figure__caption,
.intro,
.kicker,
.label,
.breadcrumb,
.progress-bar__step--complete,
.toolbar__list .icon-button--grey,
.article-teaser__date,
.gallery__image-caption,
.intro-box--orange,
.intro-box--orange .intro-box__icon,
.intro-box--orange .intro-box__headline,
.intro-box--orange .intro-box__phone,
.button--orange .button__text,
.magazine-menu__list-item:hover .magazine-menu__link,
.magazine-menu__list-item--active .magazine-menu__link,
.navigation-a-z__list-item:hover .link--inverted,
.teaser-box--orange .teaser-box__icon--transparent,
.jobs-teaser-box--orange .jobs-teaser-box__icon--transparent,
.intro-box--orange .intro-box__icon,
.newsletter-box__icon,
.newsletter-box .headline--inverted,
.newsletter-box,
.tab-box__tab-list-item[aria-selected='true'],
.teaser-box--orange .teaser-box__headline,
.teaser-box--orange,
.teaser-box--orange .phone__text,
.teaser-box--orange .phone__title,
.teaser-box--orange .teaser-box__icon,
.teaser-box--orange .teaser-box__intro,
.jobs-teaser-box--orange .teaser-box__headline,
.jobs-teaser-box--orange,
.jobs-teaser-box--orange .phone__text,
.jobs-teaser-box--orange .phone__title,
.jobs-teaser-box--orange .jobs-teaser-box__icon,
.jobs-teaser-box--orange .jobs-teaser-box__intro,
.button--action .button__text,
.list-group__date,
.topics-box__link,
.topic-box:not(.topic-box--blue) .topics-box__link:hover,
.topic-box:not(.topic-box--blue) .topics-box__link:focus,
.topics-box--orange .topics-box__icon,
.topics-box--orange .topics-box__link,
.topics-box--orange .topics-box__link-icon,
.topics-box--orange .topics-box__headline,
.contact-box,
.contact-box__headline,
.contact-box__icon,
.contact-box .label,
.contact-box__number-link,
.contact-box__number-link:focus,
.contact-box__number-link:hover,
.publication__headline,
.intro-box__icon,
.intro-box--orange .intro-box__icon,
.intro-box__headline,
.intro-box:not(.intro-box--blue):not(.intro-box--orange) {
  color: #000 !important; }

.topics-box--blue .topics-box__headline {
  color: #fff !important; }

.action-list__item--active .action-list__link {
  background-color: #484848 !important;
  color: #fff !important;
  padding: 0 0.5rem; }

.stage__headline,
.facts__kicker,
.stage-hero__wrapper:not(.stage-hero--bg-dark):not(.stage-hero--bg-light) .stage-hero__headline,
.stage-hero__wrapper:not(.stage-hero--bg-dark):not(.stage-hero--bg-light) .stage-hero__intro,
.stage-hero__wrapper:not(.stage-hero--bg-dark):not(.stage-hero--bg-light) .stage-hero__more-link,
.stage-landing__headline,
.stage-landing__intro,
.stage-landing__link {
  background-color: #484848 !important;
  border-radius: 0.4rem;
  color: #fff !important;
  padding: 1rem; }

.article-teaser__highlight,
.radio__indicator,
.checkbox__indicator,
.topics-box--orange .topics-box__list-item:not(:last-child) {
  border-color: #000 !important; }

.bill__title--shifted,
.bill__data--info,
.header__meta-navigation-link {
  color: #484848 !important; }

.facts__kicker,
.intro-box--blue .intro-box__icon,
.intro-box--blue .intro-box__headline,
.stage-dark__section .kicker,
.topics-box__icon--transparent,
.teaser-box__icon--transparent,
.jobs-teaser-box__icon--transparent,
.topics-box--inverted .topics-box__link,
.topics-box--inverted .topics-box__link:hover,
.topics-box--inverted .topics-box__link:focus,
.topics-box--inverted .topics-box__link-icon,
.faq--inverted,
.faq--inverted .headline,
.accordion--inverted .accordion__title,
.accordion--inverted .accordion__content,
.teaser-box--blue .teaser-box__headline,
.jobs-teaser-box--blue .jobs-teaser-box__headline {
  color: #fff !important; }

.header__navigation-link.header__navigation-link--active,
.header__navigation-link:not(.u-fading-out)[aria-expanded='true'],
.header__navigation-link:hover,
.header__navigation-link:not(.u-unvisible-focus):focus,
.header__meta-navigation-link--important {
  color: #757575 !important; }

.header__navigation > .header__navigation-item > .header__navigation-link.header__navigation-link--active,
.header__navigation > .header__navigation-item > .header__navigation-link:not(.u-fading-out)[aria-expanded='true'],
.header__navigation > .header__navigation-item > .header__navigation-link:hover,
.header__navigation > .header__navigation-item > .header__navigation-link:not(.u-unvisible-focus):focus {
  box-shadow: inset 0 -4px #757575 !important; }

.facts__kicker {
  display: inline-block; }

.stage-landing .button--outline,
.facts .button--outline,
.stage-distribution .button--outline,
.image-teaser .button--outline,
.teaser-box .button--inverted.button--blue,
.jobs-teaser-box .button--inverted.button--blue {
  background-color: #484848 !important;
  border-color: #fff !important;
  color: #fff !important; }
  .stage-landing .button--outline:hover, .stage-landing .button--outline:focus,
  .facts .button--outline:hover,
  .facts .button--outline:focus,
  .stage-distribution .button--outline:hover,
  .stage-distribution .button--outline:focus,
  .image-teaser .button--outline:hover,
  .image-teaser .button--outline:focus,
  .teaser-box .button--inverted.button--blue:hover,
  .teaser-box .button--inverted.button--blue:focus,
  .jobs-teaser-box .button--inverted.button--blue:hover,
  .jobs-teaser-box .button--inverted.button--blue:focus {
    background-color: #484848 !important;
    border-color: #fff !important;
    color: #fff !important; }

.facts__image {
  opacity: 0.3; }

.details__content,
.contact-box,
.faq:not(.faq--blue):not(.faq--orange),
.intro-box:not(.intro-box--blue):not(.intro-box--orange),
.navigation-a-z__wrapper,
.publication,
.teaser-box:not(.teaser-box--orange):not(.teaser-box--blue),
.jobs-teaser-box:not(.jobs-teaser-box--orange):not(.jobs-teaser-box--blue),
.topics-box:not(.topics-box--inverted),
.figure__media-overlay--video {
  background-color: #e3e4e7 !important; }

.intro-box:not(.intro-box--blue):not(.intro-box--orange) .intro-box__headline,
.intro-box:not(.intro-box--blue):not(.intro-box--orange) .intro-box__headline,
.intro-box:not(.intro-box--blue):not(.intro-box--orange) .intro-box__icon,
.intro-box:not(.intro-box--blue):not(.intro-box--orange) .intro-box__text,
.intro-box--blue .button--blue {
  color: #484848 !important; }

.intro-box--orange .button--orange {
  background-color: #000 !important;
  color: #fff !important; }
  .intro-box--orange .button--orange .button__text {
    color: #fff !important; }

.intro-box--blue .button--blue {
  background-color: #fff !important; }
